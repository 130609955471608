import { gsap } from 'gsap'
import debounce from 'lodash.debounce'
import 'normalize.css'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import {
  setLoader,
  setLocale,
  setWindowDimensions,
} from '../actions/pageActionCreators'
import appStrings from '../appStrings'
import { AppLocale } from '../constants'
import { LocalizedRouter } from '../modules'
import './App.css'
import AppRoutes from './AppRoutes'
import { GlobalStyle } from './AppStyles'
import NotFound from './NotFound'
import SEO from './SEO'
import Loader from './shared/Loader'
import PopUp from './Popup'

function App({ locale, setLocale, setWindowDimensions, showLoader }) {
  const { pathname } = useLocation()

  const onResizeDebounced = debounce(
    () =>
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    700,
  )

  const onResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', onResizeDebounced)
    return () => {
      window.removeEventListener('resize', onResizeDebounced)
    }
  }, [])

  useEffect(() => {
    onResize()
  }, [])

  const stripLanguageLocale = (lc) => {
    return lc.includes('-') ? lc.substr(0, lc.indexOf('-')) : lc
  }

  const getLocalFromUrl = () => {
    const lc = pathname.split('/')[1]
    return Object.values(AppLocale).includes(lc) ? lc : null
  }

  const getLocaleFromNavigator = () => {
    let lc =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage
    lc = stripLanguageLocale(lc)
    return Object.values(AppLocale).includes(lc) ? lc : null
  }

  const getAndSetLocale = () => {
    const lc =
      locale ||
      getLocalFromUrl() ||
      getLocaleFromNavigator() ||
      AppLocale.English
    setLocale(lc)
  }

  useEffect(() => {
    getAndSetLocale()
  }, [])

  let app = useRef()
  let tl = gsap.timeline()
  useEffect(() => {
    tl.to(app.current, { duration: 0, visibility: 'visible' })
  }, [tl])

  return (
    <LocalizedRouter
      RouterComponent={BrowserRouter}
      locales={AppLocale}
      appStrings={appStrings}
      locale={locale}
    >
      {showLoader && <Loader withPromo locale={locale} />}

      <PopUp />
      {/*<Video />*/}
      <div className="app-container" ref={app}>
        <Switch>
          <AppRoutes />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>

      <SEO />
      <GlobalStyle />
    </LocalizedRouter>
  )
}

App.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
  setWindowDimensions: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  windowSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  locale: state.page.locale,
  windowSize: state.page.windowSize,
  showLoader: state.page.showLoader,
})

export default connect(mapStateToProps, {
  setLocale,
  setLoader,
  setWindowDimensions,
})(App)
