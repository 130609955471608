import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import Logo from '../assets/images/logo.png'

interface SEOProps {
  title?: string
  description?: string
  articleBody?: string
  datePublished?: string
  dateModified?: string
  cover?: string
  readTime?: string
  author?: string
  type?: 'Restaurant' | 'NewsArticle'
}

interface Config {
  url: string
  defaultTitle: string
  defaultDescription: string
  social: {
    facebook: string
    twitter: string
  }
  socialLinks: {
    facebook: string
    twitter: string
    instagram: string
  }
  address: {
    street: string
    region: string
    country: string
    postalCode: string
    locality: string
  }
  contact: {
    email: string
    phone: string
  }
}

const SEO: React.FC<SEOProps> = React.memo(
  ({
    title,
    description,
    articleBody = '',
    datePublished = '',
    dateModified = '',
    cover,
    readTime = '',
    author = '',
    type = 'Restaurant',
  }) => {
    const location = useLocation()
    const intl = useIntl()

    const config: Config = {
      url: 'https://www.pardalo.gr',
      defaultTitle: intl.messages['meta.defaultTitle'] as string,
      defaultDescription: intl.messages['meta.defaultDescription'] as string,
      social: {
        facebook: '143527132486673',
        twitter: '@PKatsiki',
      },
      socialLinks: {
        facebook: 'https://www.facebook.com/pardalo/',
        twitter: 'https://twitter.com/pkatsiki',
        instagram: 'instagram.com/pardalo_katsiki_lefkas/',
      },
      address: {
        street: intl.messages['meta.street'] as string,
        region: intl.messages['meta.region'] as string,
        country: intl.messages['meta.country'] as string,
        postalCode: intl.messages['meta.postalCode'] as string,
        locality: intl.messages['meta.locality'] as string,
      },
      contact: {
        email: 'hello@pardalo.gr',
        phone: '+302645041769',
      },
    }

    const structuredDataArticle = `{
    "@context": "http://schema.org",
    "@type": "${type}",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://google.com/article"
    },
    "headline": "${description}",
    "image": "${cover ? `${config.url}${cover}` : `${config.url}${Logo}`}",
    "datePublished": "${datePublished}",
    "dateModified": "${dateModified}",
    "author": {
      "@type": "Person",
      "name": "${author}"
    },
    "articleBody": "${articleBody}",
    "publisher": {
      "@type": "Organization",
      "name": "${author}",
      "logo": {
        "@type": "ImageObject",
        "url": "${config.url}${Logo}"
      }
    },
    "description": "${description}",
    "url": "${config.url}${location.pathname}"
  }`

    const structuredDataLocalBusiness = `{
    "@context": "http://schema.org",
    "@type": "${type}",
    "image": ["${config.url}${Logo}"],
    "@id": "${config.url}",
    "name": "${config.defaultTitle}",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "${config.address.street}",
      "addressLocality": "${config.address.locality}",
      "addressRegion": "${config.address.region}",
      "postalCode": "${config.address.postalCode}",
      "addressCountry": "${config.address.country}"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 38.664,
      "longitude": 20.650328
    },
    "url": "${config.url}",
    "telephone": "${config.contact.phone}",
    "servesCuisine": "Greek",
    "priceRange": "$$",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ],
        "opens": "12:00",
        "closes": "23:30"
      }
    ],
    "menu": "https://www.pardalo.gr/el/restaurant/menu",
    "acceptsReservations": "True",
    "sameAs": [
      "${config.socialLinks.twitter}",
      "${config.socialLinks.facebook}",
      "${config.socialLinks.instagram}"
    ]
  }`

    return (
      <Helmet>
        <meta
          name="description"
          content={
            description
              ? (intl.messages[description] as string)
              : config.defaultDescription
          }
        />
        <meta
          name="image"
          content={cover ? `${config.url}${cover}` : `${config.url}${Logo}`}
        />
        <meta property="og:url" content={`${config.url}${location.pathname}`} />
        <meta
          property="og:type"
          content={type === 'NewsArticle' ? 'NewsArticle' : 'Restaurant'}
        />
        <meta
          property="og:title"
          content={
            title
              ? `${config.defaultTitle} | ${intl.messages[title] as string}`
              : config.defaultTitle
          }
        />
        <meta
          property="og:description"
          content={
            description
              ? (intl.messages[description] as string)
              : config.defaultDescription
          }
        />
        <meta
          property="og:image"
          content={cover ? `${config.url}${cover}` : `${config.url}${Logo}`}
        />
        <meta property="fb:app_id" content={config.social.facebook} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={config.socialLinks.twitter} />
        <meta name="twitter:site" content={config.social.twitter} />
        <meta
          name="twitter:title"
          content={
            title
              ? `${config.defaultTitle} | ${intl.messages[title] as string}`
              : config.defaultTitle
          }
        />
        <meta
          name="twitter:description"
          content={
            description
              ? (intl.messages[description] as string)
              : config.defaultDescription
          }
        />
        <meta
          name="twitter:image:src"
          content={cover ? `${config.url}${cover}` : `${config.url}${Logo}`}
        />
        {type === 'NewsArticle' && (
          <>
            <meta name="twitter:label1" content="Reading time" />
            <meta name="twitter:data1" content={`${readTime} min read`} />
            <meta name="author" content="Ismail Ghallou" />
            <meta name="article:published_time" content={datePublished} />
          </>
        )}
        <script type="application/ld+json">
          {type === 'NewsArticle'
            ? structuredDataArticle
            : structuredDataLocalBusiness}
        </script>
        <title>
          {title
            ? `${config.defaultTitle} | ${intl.messages[title] as string}`
            : config.defaultTitle}
        </title>
        <html lang={intl.locale} dir="ltr" />
      </Helmet>
    )
  },
)

SEO.displayName = 'SEO'

export default SEO
