import React from 'react'
import { useIntl } from 'react-intl'
import { NavLink, NavLinkProps } from 'react-router-dom'

interface LocalizedNavLinkProps extends Omit<NavLinkProps, 'to'> {
  to: string
  isinactive?: boolean
}

export function LocalizedNavLink({
  to,
  isinactive,
  ...props
}: LocalizedNavLinkProps) {
  const { locale } = useIntl()

  return isinactive ? (
    <NavLink {...props} to="#" />
  ) : (
    <NavLink {...props} to={`/${locale}/${to}`} />
  )
}
