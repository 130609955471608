import { Formik } from 'formik'
import { gsap, Power3 } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import React, { useRef, useState } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import * as yup from 'yup'
import Footer from './Footer'
import { media } from '../../../assets/Styles'
import Debug from './Debug'
import { useIntl } from 'react-intl'
import axios from 'axios'
import Background from '../Background'
import Confirmation from '../Confirmation'
import { useHistory } from 'react-router-dom'

gsap.registerPlugin(CSSPlugin)

const Styling = styled.div.attrs({
  className: 'wizard-wrapper',
})`
  z-index: 100;
  position: absolute;
  left: -100%;
  width: 100%;
  padding: 0 0 2rem 0;

  ${media.phone`
    width: 100%;
    overflow-y: scroll;
  `}
  .form-wrapper {
    ${media.phone`
      margin: 1rem 1rem;
      padding: 0 1rem 1rem;
    `}
    position: relative;
    min-height: auto;
    padding: 0 4rem 2rem;
    margin: 0 auto;
    max-width: 800px;
    margin: 2rem auto;
    max-width: 800px;
    background: #f2f2f2;
    border: 1px solid;

    .form-container {
      width: 100%;
      position: relative;
    }
  }

  .footer-wrapper {
  }
`

Wizard.Page = ({ children, parentState }) => {
  return children(parentState)
}
const REQUEST_SUBMIT = 'REQUEST_SUBMIT'
const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE'
const RESET_FORM = 'RESET_FORM'

export default function Wizard(props) {
  const [page, setPage] = React.useState(0)
  const [status, setStatus] = useState(null)
  const [values, setValues] = React.useState({
    values: props.initialValues,
  })
  const formikRef = useRef(null)
  const history = useHistory()
  const intl = useIntl()

  function resetForm() {
    formikRef.current.resetForm()
    setStatus(RESET_FORM)
    // animateOnExit()
    history.push(`/${intl.locale}/restaurant/location`)
  }

  const ReservationSchema = yup.object().shape({
    date: yup
      .string()
      .nullable() // Allow null values
      .required('Date is required'), // Adjust based on your needs.required('Date is required'),
    time: yup.string().required('Time is required'),
    people: yup.object().nullable().required('Number of people is required'),
  })

  const InfoSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email().required('Email is required'),
    phone: yup
      .object()
      .shape({
        phoneNumber: yup
          .string()
          .nullable()
          .required('Phone number is required')
          .test('phone-validation', 'Invalid phone number', function (value) {
            const { path, createError } = this

            // Call the custom phoneValidator function
            return phoneValidator({ phoneNumber: value, ...this.parent })
              .then(() => true) // Validation passed
              .catch(
                (error) => createError({ path, message: error }), // Return custom error message
              )
          }),
        countryCode: yup
          .string()
          .nullable()
          .required('Country code is required'),
        areaCode: yup.string().nullable().required('Area code is required'),
        isoCode: yup.string().nullable().required('ISO code is required'),
      })
      .required('Phone object is required'),
  })

  const phoneValidator = (value) => {
    return new Promise((resolve, reject) => {
      // Ensure `value` is an object with expected properties
      if (value && value.phoneNumber && value.countryCode) {
        // Perform custom validation logic here
        if (value.phoneNumber.trim() === '') {
          return reject('Phone number is required')
        }
        return resolve(true)
      }
      return reject('Invalid phone number')
    })
  }

  const next = (values) => {
    setPage(Math.min(page + 1, props.children.length - 1))
    setValues(values)
    // formatDateTime(values.date, values.time)
  }

  const previous = () => {
    console.log('back')
    setPage(Math.max(page - 1, 0))
  }

  const validate = (values) => {
    const activePage = React.Children.toArray(props.children)[page]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  function formatDateTime(date, time) {
    return date.split('T')[0] + 'T' + time
  }

  function formatForAirtable(values) {
    const { date, time, name, phone, people, email, comments } = values
    return {
      fields: {
        Name: name,
        Country: phone.isoCode,
        Telephone: phone.countryCode + phone.phoneNumber,
        People: people.value,
        'Date and time': formatDateTime(date, time),
        Email: email,
        Comments: comments,
      },
    }
  }

  const handleSubmit = (values) => {
    const { children, onSubmit } = props
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1

    if (isLastPage) {
      const airtableData = formatForAirtable(values)
      const makeData = {
        name: values.name,
        telephone: values.phone.countryCode + values.phone.phoneNumber,
        date: new Date(values.date).toLocaleDateString('el-gr', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
        time: new Date(values.timeObject).toTimeString('el-gr', {
          hour: '2-digit',
          minute: '2-digit',
        }),
        comments: values.comments,
      }
      const airtableUrl =
        'https://api.airtable.com/v0/' +
        'appgBJvNSk69iBH0F' +
        '/' +
        'Reservations'
      const makeUrl =
        'https://hook.eu1.make.com/bf886lr8baw7vhld28alc3kn4g6hnvzq'
      const axiosConfig = {
        headers: {
          Authorization:
            'Bearer ' +
            'pat21l2ZdckUluGot.1af1e31a4cfbf803acf1a83f44b1d080b7928908a19a82f10b3ba8ba0b66d100',
          'Content-Type': 'application/json',
        },
      }
      setStatus(REQUEST_SUBMIT)
      axios
        .all([
          axios.post(airtableUrl, airtableData, axiosConfig),
          axios.post(makeUrl, makeData, {
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        ])
        .then((resp) => setStatus(SEND_MESSAGE_SUCCESS))
        .catch((error) => setStatus(SEND_MESSAGE_FAILURE))
    } else {
      next(values)
    }
  }

  const submitForm = (values) => {}

  const getValidationSchema = (page) => {
    const validationSchemas = [ReservationSchema, InfoSchema, null]
    return validationSchemas[page]
  }

  const animateOnEnter = (node) => {
    const tl = gsap.timeline()
    return tl.from(node, 0.7, {
      ease: Power3.easeInOut,
      autoAlpha: 0,
      onComplete: () => {
        // Scroll to the top of the page once the animation is complete
        window.scrollTo(0, 0)
      },
    })
  }

  const animateOnExit = (node) => {
    const timeline = gsap.timeline()
    return timeline.to(node, 0.7, {
      ease: Power3.easeInOut,
      autoAlpha: 0,
    })
  }

  const { children, initialValues, onSubmit, ...rest } = props

  const activePage = React.Children.toArray(children)[page]
  return (
    <Styling>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(page)}
        validate={validate}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        {(props) => (
          <div className="form-wrapper">
            <form onSubmit={props.handleSubmit}>
              <TransitionGroup component={null}>
                <Transition
                  mountOnEnter
                  key={page}
                  onEnter={(node) => animateOnEnter(node)}
                  onExit={(node) => animateOnExit(node)}
                  timeout={{ enter: 1000, exit: 0 }}
                >
                  <div className="form-container">
                    {React.cloneElement(activePage, {
                      parentState: { ...props },
                    })}

                    <Footer previous={previous} page={page} status={status} />
                    {status === REQUEST_SUBMIT ||
                      (status === SEND_MESSAGE_SUCCESS && <Background />)}
                    {status === SEND_MESSAGE_SUCCESS && (
                      <Confirmation
                        success
                        resetForm={resetForm}
                        messageId="reserve.successMessage"
                      />
                    )}
                    {status === SEND_MESSAGE_FAILURE && (
                      <p>
                        Error sending message. Please call us on +302645041769.
                      </p>
                    )}
                  </div>
                </Transition>
              </TransitionGroup>

              {process.env.NODE_ENV === 'development' && <Debug />}
            </form>
          </div>
        )}
      </Formik>
    </Styling>
  )
}
