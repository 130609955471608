import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { device, sizes } from '../../../assets/Styles'
import SEO from '../../SEO'
import Breadcrumb from '../../shared/Breadcrumb'
import Actions from './Actions'
import Address from './Address'
import Map from './Map'

const StyledLocation = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}px;

  @media ${device.tablet} {
    overflow-y: scroll;
    padding-top: 3rem;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;

    @media ${device.tablet} {
      flex-direction: column;
    }

    .address-wrapper {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      @media ${device.laptop} {
        padding: 3rem;
      }
      @media ${device.tablet} {
        padding: 2rem 0 2rem;
      }

      .address-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media ${device.tablet} {
          align-items: center;
          padding: 1rem;
        }

        .breadcrump-wrapper {
          align-self: flex-start;
          margin: 1rem 0 3rem 0;

          @media ${device.tablet} {
            margin: 0 auto;
            position: relative;
          }
        }
      }
    }
  }
`

const Location = React.memo(({ windowSize }) => {
  const routes = [
    { title: 'home.title', path: '' },
    { title: 'restaurant.title', path: 'restaurant' },
    { title: 'restaurant.contact', path: 'restaurant/location' },
  ]

  // Add check for windowSize
  if (!windowSize?.height) {
    return null
  }

  return (
    <StyledLocation height={windowSize.height}>
      <SEO title="address.header" />
      <div className="container">
        <div className="address-wrapper">
          <div className="address-container">
            {windowSize.width > sizes.phone && (
              <div className="breadcrump-wrapper">
                <Breadcrumb routes={routes} />
              </div>
            )}
            <Address />
            <Actions />
          </div>
        </div>
        <Map windowSize={windowSize} />
      </div>
    </StyledLocation>
  )
})

Location.propTypes = {
  windowSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps)(Location)
