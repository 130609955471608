import React, { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { keyframes } from 'styled-components'
import Close from '../assets/Close'
import { device } from '../assets/Styles'
import gsap from 'gsap'
import { LocalizedNavLink } from '../modules'

const fadeAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`

const PopupWrapper = styled.div`
  z-index: 1100;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  overflow-y: scroll;
  .container {
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;

    @media ${device.tablet} {
      padding: 2rem;
    }
  }

  .close-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: 100%;
    padding: 2rem 1rem;
  }

  h1 {
    font-family: 'Noto Serif', serif;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    color: #333;

    @media ${device.tablet} {
      font-size: 2.5rem;
    }
  }

  .main-message {
    font-family: 'Murecho', sans-serif;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    color: #444;

    @media ${device.tablet} {
      font-size: 1.25rem;
    }
  }

  .winter-wish {
    font-family: 'Murecho', sans-serif;
    text-align: center;
    font-size: 1.15rem;
    margin-bottom: 3rem;
    color: #333;
    font-weight: 500;

    @media ${device.tablet} {
      font-size: 1.3rem;
    }
  }

  .job-notice {
    font-family: 'Murecho', sans-serif;
    text-align: center;
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;

    @media ${device.tablet} {
      font-size: 1.1rem;
    }
  }

  .form-link {
    color: #4a90e2;
    text-decoration: none;
    transition: color 0.3s ease;
    font-weight: 500;
    animation: ${fadeAnimation} 2s ease-in-out infinite;

    &:hover {
      color: #2171cd;
      animation-play-state: paused;
    }
  }

  .phone-link {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;
    font-weight: 500;

    &:hover {
      color: #4a90e2;
    }
  }
`

const MESSAGES = {
  NOTICE_TITLE: 'popup.notice.title',
  NOTICE_MAIN: 'popup.notice.main',
  NOTICE_OUTRO: 'popup.notice.outro',
  SECONDARY_MESSAGE: 'popup.secondary.message',
} as const

const usePopupAnimation = () => {
  const popupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = popupRef.current
    if (!element) return

    const showTimeline = gsap.timeline()
    showTimeline.to(element, {
      duration: 0.5,
      ease: 'power3.inOut',
      autoAlpha: 0.98,
      delay: 5,
    })

    return () => {
      showTimeline.kill()
    }
  }, [])

  const closePopup = () => {
    if (!popupRef.current) return

    gsap.to(popupRef.current, {
      duration: 0.4,
      ease: 'power3.inOut',
      autoAlpha: 0,
      onComplete: () => {
        if (popupRef.current) {
          popupRef.current.style.visibility = 'hidden'
        }
      },
    })
  }

  return { popupRef, closePopup }
}

const Popup = () => {
  const { popupRef, closePopup } = usePopupAnimation()

  const handleLinkClick = () => {
    closePopup()
  }

  return (
    <PopupWrapper ref={popupRef}>
      <div className="container">
        <div className="content">
          <h1>
            <FormattedMessage id={MESSAGES.NOTICE_TITLE} />
          </h1>
          <p className="main-message">
            <FormattedMessage id={MESSAGES.NOTICE_MAIN} />
          </p>
          <p className="winter-wish">
            <FormattedMessage id={MESSAGES.NOTICE_OUTRO} />
          </p>
          <p className="job-notice">
            <FormattedMessage id="popup.job.text" />{' '}
            <LocalizedNavLink
              to="contact"
              className="form-link"
              onClick={handleLinkClick}
            >
              <br />
              <FormattedMessage id="popup.job.form" />
            </LocalizedNavLink>{' '}
            <FormattedMessage id="popup.job.or" />{' '}
            <a href="tel:+302645041769" className="phone-link">
              <FormattedMessage id="popup.job.call" />
            </a>
          </p>
        </div>
        <div className="close-wrapper">
          <Close color="black" close={closePopup} />
        </div>
      </div>
    </PopupWrapper>
  )
}

export default React.memo(Popup)
