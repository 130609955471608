import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { media, sizes } from '../../../assets/Styles'
import Breadcrump from '../../shared/Breadcrumb'
import MenuRoutes from './MenuRoutes'
import Nav from './Nav/Nav'

const Styling = styled.div`
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 1rem;
  min-height: ${window.innerHeight}px;
  .logo-wrapper {
    ${media.phone`
      display: flex;
      justify-content: center;
      margin-top: 5rem;
      position: relative;
      top: 0;
      left: 0;
    `};
  }
  .nav-wrapper {
    ${media.phone`
      margin-top: 6rem;
      flex-direction: column;
    `};
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto 2rem;
    max-width: 780px;
    margin-top: 3rem;
    ${media.tablet`
      width: 100%;
    `};

    .nav-container {
      .breadcrump-wrapper {
        margin-bottom: 3rem;
        ${media.phone`
          margin: 0;
        `};
      }
    }
  }
`

const Menu = (props) => {
  const routes = [
    { title: 'home.title', path: '' },
    { title: 'restaurant.title', path: 'restaurant' },
    { title: 'restaurant.menu', path: 'restaurant/menu/appetizers' },
  ]
  const { windowSize } = props

  return (
    <Styling>
      <div className="nav-wrapper">
        <div className="nav-container">
          {windowSize.width > sizes.phone && (
            <div className="breadcrump-wrapper">
              <Breadcrump routes={routes} />
            </div>
          )}
          <Nav />
        </div>
      </div>
      <MenuRoutes />
    </Styling>
  )
}

Menu.propTypes = {
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps, {})(Menu)
