import { createBrowserHistory } from 'history'
import 'normalize.css'
import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import App from './components/App'
import './index.css'
import rootReducer from './reducers'
import * as serviceWorker from './serviceWorker'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'

window.loadPromise = new Promise((resolve) => {
  window.addEventListener('DOMContentLoaded', resolve)
})

const middleware = [thunk]
// if (process.env.NODE_ENV !== 'production') {
//   middleware.push(logger)
//   import('why-did-you-update').then((module) => {
//     module.whyDidYouUpdate(React)
//   })
// }

const queryClient = new QueryClient()

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose,
  ),
)

const history = createBrowserHistory()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <App />
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
