import { Power3 } from 'gsap'
import 'mapbox-gl/dist/mapbox-gl.css'
// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
import mapboxgl from 'mapbox-gl'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import ReactMapGL, { FlyToInterpolator, Marker } from 'react-map-gl'
import styled from 'styled-components'
import Pin from '../../shared/Pin'

// Worker configuration for mapbox-gl
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibWFyaW5vc3phayIsImEiOiItdjZkVFQwIn0.Fb4w2Uwb-fzwvkhKAHUdqg'
const MAPBOX_STYLE_URL = 'mapbox://styles/marinoszak/ckjd361g0ae3519l98mpkoyc2'
const INITIAL_LOCATION = {
  LONGITUDE: 20.650328,
  LATITUDE: 38.7599,
}

const MapWrapper = styled.div`
  flex: 1;
  position: relative;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;

  @media (max-width: 780px) {
    width: 100%;
    height: ${(props) => (props.height * 6) / 8}px;
  }
`

const Map = ({ windowSize }) => {
  const calculateDimensions = useCallback(() => {
    let { width, height } = windowSize

    if (width <= 780) {
      height = (height * 6) / 8
    } else if (width <= 1440) {
      width = width / 2
    } else {
      width = 780
    }

    return { width, height }
  }, [windowSize])

  const [viewport, setViewport] = useState({
    latitude: 38.664,
    longitude: INITIAL_LOCATION.LONGITUDE,
    zoom: 10.5,
    bearing: 0,
    pitch: 30,
    ...calculateDimensions(),
  })

  const [settings] = useState({
    dragPan: true,
    dragRotate: false,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: true,
    minZoom: 10,
    maxZoom: 17,
    minPitch: 0,
    maxPitch: 30,
    dragToRotate: false,
  })

  useEffect(() => {
    const dimensions = calculateDimensions()
    setViewport((prev) => ({
      ...prev,
      ...dimensions,
    }))
  }, [windowSize, calculateDimensions])

  const flyTo = useCallback(() => {
    setViewport((prev) => ({
      ...prev,
      pitch: 15,
      zoom: 15,
      latitude: INITIAL_LOCATION.LATITUDE,
      longitude: INITIAL_LOCATION.LONGITUDE,
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 2000,
      transitionEasing: Power3.easeInOut,
    }))
  }, [])

  const renderMarker = useCallback(
    () => (
      <Marker
        longitude={INITIAL_LOCATION.LONGITUDE}
        latitude={INITIAL_LOCATION.LATITUDE}
        onClick={flyTo}
      >
        <Pin size={20} onMarkerClick={flyTo} />
      </Marker>
    ),
    [flyTo],
  )

  const dimensions = calculateDimensions()

  return (
    <MapWrapper width={dimensions.width} height={dimensions.height}>
      <ReactMapGL
        {...viewport}
        {...settings}
        mapStyle={MAPBOX_STYLE_URL}
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
      >
        {renderMarker()}
      </ReactMapGL>
    </MapWrapper>
  )
}

Map.propTypes = {
  windowSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
}

export default Map
