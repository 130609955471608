import React from 'react'
import styled from 'styled-components'
import { device } from '../../../assets/Styles'
import Button from './Button'
import Close from '../../../assets/Close'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import LanguageSwitcher from '../../shared/LanguageSwitcher'

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const FooterContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LanguageContainer = styled.div`
  margin: 0 auto;

  .language-wrapper {
    li {
      display: inline-block;

      a {
        width: auto;
        float: left;

        &:first-child {
          margin-right: 0.5rem;
        }

        &.active {
          border-left: none;
          border-bottom: 1px solid #222;
        }
      }
    }
  }
`

const PaginationWrapper = styled.nav`
  ul {
    margin: 0;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;

    @media ${device.phone} {
      justify-content: center;
    }

    li {
      height: 32px;
      margin: 0 3px;
      padding: 0 10px;
      line-height: 2rem;
      border: 1px solid;
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      @media ${device.phone} {
        margin: 0 10px;
      }

      &.active {
        background: #00140f;
        color: white;
      }
    }
  }
`

const Footer = ({ page, previous, status }) => {
  const history = useHistory()
  const intl = useIntl()

  const handleNextClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getButtonConfig = () => {
    const configs = {
      0: {
        showClose: true,
        showLanguage: true,
        buttons: [
          {
            name: intl.messages['reserve.buttons.next'],
            onClick: handleNextClick,
            type: 'submit',
            position: 'right',
          },
        ],
      },
      1: {
        buttons: [
          {
            name: intl.messages['reserve.buttons.previous'],
            onClick: previous,
            type: 'button',
            position: 'left',
          },
          {
            name: intl.messages['reserve.buttons.next'],
            onClick: handleNextClick,
            type: 'submit',
            position: 'right',
          },
        ],
      },
      2: {
        buttons: [
          {
            name: intl.messages['reserve.buttons.previous'],
            onClick: previous,
            type: 'button',
            position: 'left',
          },
          {
            name:
              status === 'REQUEST_SUBMIT'
                ? intl.messages['reserve.buttons.submitting']
                : intl.messages['reserve.buttons.submit'],
            onClick: handleNextClick,
            type: 'submit',
            position: 'right',
          },
        ],
      },
    }

    return configs[page] || {}
  }

  const renderButtons = (buttons = []) => {
    return buttons.map((button, index) => (
      <div key={index} className={`button-${button.position}`}>
        <Button
          name={button.name}
          onClick={button.onClick}
          type={button.type}
          isBack={button.position === 'left'}
        />
      </div>
    ))
  }

  const config = getButtonConfig()

  return (
    <FooterWrapper>
      <FooterContainer>
        {config.showClose && (
          <Close color="black" close={() => history.push('index')} />
        )}

        {config.showLanguage && (
          <LanguageContainer>
            <div className="language-wrapper">
              <LanguageSwitcher />
            </div>
          </LanguageContainer>
        )}

        {renderButtons(config.buttons)}
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer
