import React from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'

// Import dayjs plugins
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc)
dayjs.extend(advancedFormat)

const DatePickerWithFormik = ({
  form: { setFieldValue, setFieldTouched, values },
}) => {
  // Function to disable dates before today and after September 29
  const disableDates = (current) => {
    // Get today's date and September 29 of the current year
    const today = dayjs().startOf('day')
    const sept29 = dayjs().year(today.year()).month(8).date(29) // month(8) = September (0-based index)

    // Disable dates before today and after September 29
    return current && current < today
  }

  const handleDateChange = (dateObject) => {
    if (dateObject) {
      // Convert to local date without time
      const isoDate = dateObject.startOf('day').format('YYYY-MM-DD') // "YYYY-MM-DD"
      setFieldValue('date', isoDate)
    } else {
      setFieldValue('date', null)
    }
  }

  const handleBlur = () => {
    // Ensure touched state is properly set
    setFieldTouched('date', true)
  }

  const intl = useIntl()

  // Parse the ISO date string to dayjs object for the DatePicker
  const parsedDate = values.date ? dayjs(values.date, 'YYYY-MM-DD') : null

  return (
    <DatePicker
      format={'DD/MM/YYYY'}
      value={parsedDate}
      onChange={handleDateChange}
      onBlur={handleBlur}
      picker="date"
      placeholder={intl.formatMessage({ id: 'reserve.date.placeholder' })}
      disabledDate={disableDates}
    />
  )
}

export default DatePickerWithFormik
