import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { colors, media } from '../../assets/Styles'
import LanguageSwitcher from '../shared/LanguageSwitcher'
import Burger from './Burger'
import Slide from './Slide'

const StyledRightBar = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  width: 50px;
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid ${colors.light2};
  background-color: ${colors.white};

  ${media.phone`
    z-index: 1;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    height: 100px;
    flex-direction: row-reverse;
    border-left: none;
    background-color: transparent;
  `}
`

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 50px;
  margin: 2rem 0;

  ${media.phone`
    width: auto;
    margin: 0;
    
    &:first-child {
      position: absolute;
      right: 0;
      width: 50px;
    }
    
    &:last-child {
      position: absolute;
      right: 60px;
    }
  `}
`

const RightBar = React.memo(({ windowSize, ...props }) => {
  // Early return if windowSize is not available
  if (!windowSize?.height) {
    return null
  }

  return (
    <Slide windowSize={windowSize} {...props}>
      <StyledRightBar height={windowSize.height}>
        <NavItem>
          <Burger />
        </NavItem>
        <NavItem>
          <LanguageSwitcher />
        </NavItem>
      </StyledRightBar>
    </Slide>
  )
})

RightBar.propTypes = {
  windowSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
})

export default connect(mapStateToProps)(RightBar)
