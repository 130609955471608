import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { media, sizes } from '../../assets/Styles'
import Header from '../shared/Header'
import Embroideries from './Embroideries'
import HomeRoutes from './HomeRoutes'
import Lathyria from './Lathyria'
import Lentils from './Lentils'
import Village from './Village'
import Footer from '../shared/Footer'

const Container = styled.div`
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  ${media.phone`
    overflow-y: scroll;
    height: 100%;
  `};
`

const Home = ({ locale, windowSize }) => {
  return (
    <Container>
      <Header locale={locale} />
      {windowSize.width > sizes.phone ? (
        <React.Fragment>
          <HomeRoutes />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Village in />
          <Embroideries in />
          <Lentils in />
          <Lathyria in />
          <Footer />
        </React.Fragment>
      )}
    </Container>
  )
}

Home.propTypes = {
  locale: PropTypes.string.isRequired,
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  windowSize: state.page.windowSize,
  locale: state.page.locale,
})

export default connect(mapStateToProps, {})(Home)
