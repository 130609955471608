import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

interface Sizes {
  desktop: number
  tablet: number
  phone: number
}

export const sizes: Sizes = {
  desktop: 1300,
  tablet: 925,
  phone: 780,
}

interface MediaFunctions {
  [key: string]: (
    strings: TemplateStringsArray,
    ...interpolations: any[]
  ) => FlattenSimpleInterpolation
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce<MediaFunctions>((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label as keyof Sizes] / 16}em) {
      ${css(...args)};
    }
  `
  return acc
}, {} as MediaFunctions)

interface DeviceSizes {
  mobileS: number
  mobileM: number
  mobileL: number
  tablet: number
  laptop: number
  laptopL: number
  desktop: number
}

const size: DeviceSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
}

interface DeviceBreakpoints {
  [key: string]: string
}

export const device = Object.keys(size).reduce<DeviceBreakpoints>(
  (acc, cur) => {
    acc[cur] = `(max-width: ${size[cur as keyof DeviceSizes]}px)`
    return acc
  },
  {} as DeviceBreakpoints,
)

export default device

// Colors interface
interface Colors {
  white: string
  light0: string
  light1: string
  light2: string
  light3: string
  light4: string
  light5: string
  light6: string
  dark0: string
  dark1: string
  dark2: string
  dark3: string
  dark4: string
  dark5: string
  dark6: string
  yellow: string
  red: string
  brown1: string
  darkGrey: string
  grey: string
  borderGrey: string
  lightBrown: string
  backgroundGreen: string
  backgroundGrey: string
  backgroundBlue: string
  green: string
  background: string
}

export const colors: Colors = {
  white: '#fff',
  light0: '#f7f7f7',
  light1: '#EDEDED',
  light2: '#EEEEEE',
  light3: '#DDDDDD',
  light4: '#CCCCCC',
  light5: '#BBBBBB',
  light6: '#AAAAAA',
  dark0: '#222222',
  dark1: '#333333',
  dark2: '#444444',
  dark3: '#555555',
  dark4: '#666666',
  dark5: '#777777',
  dark6: '#999999',
  yellow: '#fffde7',
  red: '#EB6060',
  brown1: '#f0f0e8',
  darkGrey: '#212121',
  grey: '#D4EDF1',
  borderGrey: '#bdbdbd',
  lightBrown: '#e4e4e4',
  backgroundGreen: '#e8f3ec',
  backgroundGrey: '#f4f0ed',
  backgroundBlue: '#F3F9FD',
  green: '#058273',
  background: 'hsl(0, 10%, 89%)',
}

// Styled components
interface StyledComponentProps {
  className?: string
}

export const Heading2 = styled.h2<StyledComponentProps>`
  font-size: 180%;
  margin: 0;
`

export const ItemWrapper = styled.div<StyledComponentProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  flex: 0 1 32%;
  padding: 2em 2.5em;
  color: #262b38;
  background: #eef0f3;
  border-top: 3px solid #eef0f3;
  transition: border-color 0.3s;
`
