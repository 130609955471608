import { useMutation } from '@tanstack/react-query'
import { submitContactForm } from './contactApi'
import { ContactFormValues, EmailConfig } from './types'

export const useContactMutation = (config: EmailConfig) => {
  return useMutation({
    mutationFn: (values: ContactFormValues) =>
      submitContactForm(values, config),
    onError: (error) => {
      console.error('Contact form submission failed:', error)
    },
  })
}
