import React, { useEffect, useMemo, useState } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { IntlShape, useIntl } from 'react-intl'
import { gsap } from 'gsap'
import { ContactFormValues, EmailConfig } from './types'
import * as Yup from 'yup'
import { useContactMutation } from './useContactMutation'
import type { UploadFile, UploadProps } from 'antd'
import { message, Select, Upload } from 'antd'
import styled from 'styled-components'
import {
  Button,
  Error,
  ErrorSpan,
  FieldWrapper,
  Input,
  Item,
  Label,
  Row,
  Span,
  TextArea,
} from './ContactStyles'
import Background from '../Background'
import Confirmation from '../Confirmation'
import { colors } from '../../../assets/Styles'

const { Dragger } = Upload

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    background: ${(props) => colors.white} !important;
    font-size: 90% !important;
    font-style: italic !important;
    transition-property: border !important;
    transition-duration: 0.2s !important;
    border: 1px solid ${(props) => colors.light6} !important;
    border-radius: 2px !important;
    min-height: 47px !important;
    padding: 8px 4px !important;

    &:hover {
      border-color: ${(props) => colors.dark2} !important;
    }
  }

  .ant-select-selection-item {
    margin: 2px !important;
  }

  .ant-select-selection-placeholder {
    font-style: italic !important;
    color: #999 !important;
  }
`

const StyledDragger = styled(Dragger)`
  .ant-upload-list-item-actions {
    .anticon-delete {
      color: rgba(0, 0, 0, 0.45);
      font-size: 16px;
      transition: all 0.3s;

      &:hover {
        color: #ff4d4f;
      }
    }
  }

  .ant-upload-list-item {
    margin-top: 8px;
  }
`

const positions = [
  { value: 'contactForm.positions.cook.a', defaultMessage: "Μάγειρας Α'" },
  { value: 'contactForm.positions.cook.b', defaultMessage: "Μάγειρας Β'" },
  { value: 'contactForm.positions.cook.c', defaultMessage: 'Μάρειρας Γ΄' },
  { value: 'contactForm.positions.souschef', defaultMessage: 'sous-chef' },
  { value: 'contactForm.positions.reception', defaultMessage: 'Υποδοχή' },
  { value: 'contactForm.positions.waiter', defaultMessage: 'Σερβιτόρος' },
  {
    value: 'contactForm.positions.waiter.assistant',
    defaultMessage: 'βοηθός Σερβιτόρου',
  },
  { value: 'contactForm.positions.barman', defaultMessage: 'Barman' },
  { value: 'contactForm.positions.dishwasher', defaultMessage: 'Λάτζα' },
] as const

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB
const ALLOWED_FILE_TYPES = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
}

export const contactSchema = (intl: IntlShape) => {
  return Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({ id: 'contactForm.validation.name' }))
      .required(intl.formatMessage({ id: 'contactForm.validation.required' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'contactForm.validation.email' }))
      .required(intl.formatMessage({ id: 'contactForm.validation.required' })),
    phone: Yup.string()
      .matches(
        /^[0-9+\-\s()]+$/,
        intl.formatMessage({ id: 'contactForm.validation.phone' }),
      )
      .min(
        7,
        intl.formatMessage({ id: 'contactForm.validation.numberOfDigits' }),
      )
      .required(intl.formatMessage({ id: 'contactForm.validation.required' })),
    address: Yup.string().required(
      intl.formatMessage({ id: 'contactForm.validation.required' }),
    ),
    positions: Yup.array()
      .min(
        1,
        intl.formatMessage({ id: 'contactForm.validation.selectPosition' }),
      )
      .required(intl.formatMessage({ id: 'contactForm.validation.required' })),
    experience: Yup.string()
      .min(
        10,
        intl.formatMessage(
          {
            id: 'contactForm.validation.minChars',
          },
          { min: 10 },
        ),
      )
      .required(intl.formatMessage({ id: 'contactForm.validation.required' })),
    cv: Yup.mixed()
      .test(
        'fileRequired',
        intl.formatMessage({ id: 'contactForm.validation.required' }),
        (value) => value !== null,
      )
      .test(
        'fileSize',
        intl.formatMessage(
          { id: 'contactForm.validation.fileSize' },
          { size: '5MB' },
        ),
        (value) => {
          if (!value) return false
          return value.size <= MAX_FILE_SIZE
        },
      )
      .test(
        'fileType',
        intl.formatMessage(
          { id: 'contactForm.validation.fileType' },
          { types: 'PDF, DOC, DOCX, JPG, PNG' },
        ),
        (value) => {
          if (!value) return false
          return Object.keys(ALLOWED_FILE_TYPES).includes(value.type)
        },
      )
      .test(
        'fileCount',
        intl.formatMessage({ id: 'contactForm.validation.singleFile' }),
        (value) => {
          if (!value) return false
          return !Array.isArray(value)
        },
      )
      .required(intl.formatMessage({ id: 'contactForm.validation.required' })),
  })
}

interface ContactFormProps {
  emailConfig: EmailConfig
  onClose: () => void
}

const initialValues: ContactFormValues = {
  name: '',
  email: '',
  phone: '',
  address: '',
  positions: [],
  experience: '',
  cv: null,
}

export const ContactForm: React.FC<ContactFormProps> = ({
  emailConfig,
  onClose,
}) => {
  const intl = useIntl()
  const [buttonText, setButtonText] = useState(
    intl.formatMessage({ id: 'contactForm.submit' }),
  )
  const contactMutation = useContactMutation(emailConfig)
  const [showAllErrors, setShowAllErrors] = useState(false)

  const formRef = React.useRef(null)
  const backgroundRef = React.useRef(null)
  const confirmationRef = React.useRef(null)
  const [prevErrors, setPrevErrors] = useState({})

  useEffect(() => {
    gsap.set('.error-message', {
      scaleY: 0,
      opacity: 0,
    })
  }, [])

  const handleErrorAnimation = (errors, touched) => {
    Object.keys(errors).forEach((fieldName) => {
      const errorElement = document.querySelector(`[data-error="${fieldName}"]`)
      if (errorElement) {
        if (touched[fieldName] || showAllErrors) {
          gsap.to(errorElement, {
            scaleY: 1,
            opacity: 1,
            duration: 0.3,
            ease: 'power2.out',
          })
        } else {
          gsap.to(errorElement, {
            scaleY: 0,
            opacity: 0,
            duration: 0.3,
            ease: 'power2.in',
          })
        }
      }
    })

    setPrevErrors(errors)
  }

  const handleSubmit = async (
    values: ContactFormValues,
    { resetForm, setSubmitting, setTouched }: FormikHelpers<ContactFormValues>,
  ) => {
    setShowAllErrors(true)

    // Set all fields as touched
    const allFieldsTouched = Object.keys(values).reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {},
    )
    setTouched(allFieldsTouched)

    // Stop if there's no file
    if (!values.cv) {
      setSubmitting(false)
      return
    }

    try {
      setButtonText(intl.formatMessage({ id: 'contactForm.sending' }))

      const result = await contactMutation.mutateAsync(values)

      if (result?.success) {
        const tl = gsap.timeline()
        await tl
          .to(backgroundRef.current, {
            opacity: 1,
            duration: 0.3,
            display: 'block',
          })
          .to(
            confirmationRef.current,
            {
              opacity: 1,
              duration: 0.5,
              ease: 'power2.out',
              display: 'block',
            },
            '0.5',
          )

        resetForm()
        setShowAllErrors(false)
      }
    } catch (error) {
      console.error('Form submission error:', error)
      message.error(
        error instanceof Error ? error.message : 'Form submission failed',
      )
    } finally {
      setSubmitting(false)
      setButtonText(intl.formatMessage({ id: 'contactForm.submit' }))
      if (contactMutation.isError) {
        gsap.to(backgroundRef.current, {
          opacity: 0,
          duration: 0.3,
          display: 'none',
        })
      }
    }
  }
  const validationSchema = useMemo(() => contactSchema(intl), [intl])

  return (
    <>
      <div ref={backgroundRef} style={{ display: 'none', opacity: 0 }}>
        <Background />
      </div>

      <div ref={confirmationRef} style={{ display: 'none', opacity: 0 }}>
        {contactMutation.isSuccess && (
          <Confirmation success={true} resetForm={onClose} />
        )}
        {contactMutation.isError && (
          <Confirmation
            success={false}
            resetForm={() => {
              contactMutation.reset()
              gsap.set([backgroundRef.current, confirmationRef.current], {
                display: 'none',
                opacity: 0,
              })
            }}
          />
        )}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          values,
          setTouched,
        }) => {
          useEffect(() => {
            handleErrorAnimation(errors, touched)
          }, [errors, touched])

          const uploadProps: UploadProps = {
            name: 'cv',
            multiple: false,
            maxCount: 1,
            accept: Object.entries(ALLOWED_FILE_TYPES)
              .flatMap(([_, exts]) => exts)
              .join(','),
            beforeUpload: (file) => {
              const isValidType = Object.keys(ALLOWED_FILE_TYPES).includes(
                file.type,
              )

              if (!isValidType) {
                message.error(
                  'Please upload a PDF, DOC, DOCX, JPG, or PNG file',
                )
                return Upload.LIST_IGNORE
              }

              const isLessThan5M = file.size / 1024 / 1024 < 5
              if (!isLessThan5M) {
                message.error('File must be smaller than 5MB')
                return Upload.LIST_IGNORE
              }

              return false
            },
            onChange: async (info) => {
              const { file } = info

              if (file.status === 'removed') {
                await setFieldValue('cv', null)
                await setTouched({ ...touched, cv: true }, true)
                return
              }

              const fileObj = file.originFileObj || file
              if (fileObj instanceof File) {
                await setFieldValue('cv', fileObj)
                await setTouched({ ...touched, cv: true }, true)
              }
            },
            onRemove: async () => {
              await setFieldValue('cv', null)
              await setTouched({ ...touched, cv: true }, true)
              return true
            },
            customRequest: ({ onSuccess }) => {
              onSuccess?.('ok')
            },
            fileList:
              values.cv instanceof File
                ? [
                    {
                      uid: '-1',
                      name: values.cv.name,
                      status: 'done',
                      size: values.cv.size,
                      type: values.cv.type,
                    } as UploadFile,
                  ]
                : [],
          }

          return (
            <Form ref={formRef}>
              <Row>
                <Item>
                  <FieldWrapper>
                    <Label>
                      {intl.formatMessage({ id: 'contactForm.name.label' })}
                      <Span>*</Span>
                    </Label>
                    <Field
                      as={Input}
                      type="text"
                      name="name"
                      className={
                        errors.name && (touched.name || showAllErrors)
                          ? 'error-field'
                          : ''
                      }
                      disabled={isSubmitting || contactMutation.isPending}
                    />
                    <Error className="error-message" data-error="name">
                      {errors.name && (touched.name || showAllErrors) && (
                        <ErrorSpan>{errors.name}</ErrorSpan>
                      )}
                    </Error>
                  </FieldWrapper>
                </Item>
                <Item>
                  <FieldWrapper>
                    <Label>
                      {intl.formatMessage({ id: 'contactForm.email.label' })}
                      <Span>*</Span>
                    </Label>
                    <Field
                      as={Input}
                      type="email"
                      name="email"
                      className={
                        errors.email && (touched.email || showAllErrors)
                          ? 'error-field'
                          : ''
                      }
                      disabled={isSubmitting || contactMutation.isPending}
                    />
                    <Error className="error-message" data-error="email">
                      {errors.email && (touched.email || showAllErrors) && (
                        <ErrorSpan>{errors.email}</ErrorSpan>
                      )}
                    </Error>
                  </FieldWrapper>
                </Item>
              </Row>

              <Row>
                <Item>
                  <FieldWrapper>
                    <Label>
                      {intl.formatMessage({ id: 'contactForm.phone.label' })}
                      <Span>*</Span>
                    </Label>
                    <Field
                      as={Input}
                      type="tel"
                      name="phone"
                      className={
                        errors.phone && (touched.phone || showAllErrors)
                          ? 'error-field'
                          : ''
                      }
                      disabled={isSubmitting || contactMutation.isPending}
                    />
                    <Error className="error-message" data-error="phone">
                      {errors.phone && (touched.phone || showAllErrors) && (
                        <ErrorSpan>{errors.phone}</ErrorSpan>
                      )}
                    </Error>
                  </FieldWrapper>
                </Item>
                <Item>
                  <FieldWrapper>
                    <Label>
                      {intl.formatMessage({ id: 'contactForm.address.label' })}
                      <Span>*</Span>
                    </Label>
                    <Field
                      as={Input}
                      type="text"
                      name="address"
                      className={
                        errors.address && (touched.address || showAllErrors)
                          ? 'error-field'
                          : ''
                      }
                      disabled={isSubmitting || contactMutation.isPending}
                    />
                    <Error className="error-message" data-error="address">
                      {errors.address && (touched.address || showAllErrors) && (
                        <ErrorSpan>{errors.address}</ErrorSpan>
                      )}
                    </Error>
                  </FieldWrapper>
                </Item>
              </Row>

              <FieldWrapper>
                <Label>
                  {intl.formatMessage({ id: 'contactForm.positions.label' })}
                  <Span>*</Span>
                </Label>
                <StyledSelect
                  mode="multiple"
                  onChange={async (value) => {
                    await setFieldValue('positions', value)
                    await setTouched({ ...touched, positions: true }, true)
                  }}
                  value={values.positions}
                  options={positions.map((pos) => ({
                    value: pos.value,
                    label: intl.formatMessage(
                      { id: pos.value },
                      { defaultMessage: pos.defaultMessage },
                    ),
                  }))}
                  disabled={isSubmitting || contactMutation.isPending}
                  className={
                    errors.positions && (touched.positions || showAllErrors)
                      ? 'error-field'
                      : ''
                  }
                />
                <Error className="error-message" data-error="positions">
                  {errors.positions && (touched.positions || showAllErrors) && (
                    <ErrorSpan>{errors.positions}</ErrorSpan>
                  )}
                </Error>
              </FieldWrapper>

              <FieldWrapper>
                <Label>
                  {intl.formatMessage({ id: 'contactForm.experience.label' })}
                  <Span>*</Span>
                </Label>
                <Field
                  as={TextArea}
                  name="experience"
                  rows={5}
                  className={
                    errors.experience && (touched.experience || showAllErrors)
                      ? 'error-field'
                      : ''
                  }
                  disabled={isSubmitting || contactMutation.isPending}
                />
                <Error className="error-message" data-error="experience">
                  {errors.experience &&
                    (touched.experience || showAllErrors) && (
                      <ErrorSpan>{errors.experience}</ErrorSpan>
                    )}
                </Error>
              </FieldWrapper>

              <FieldWrapper>
                <Label>
                  {intl.formatMessage({ id: 'contactForm.cv.label' })}
                  <Span>*</Span>
                </Label>
                <StyledDragger
                  {...uploadProps}
                  className={
                    errors.cv && (touched.cv || showAllErrors)
                      ? 'error-field'
                      : ''
                  }
                  disabled={isSubmitting || contactMutation.isPending}
                  style={{
                    border: '2px dashed #d9d9d9',
                    borderRadius: '4px',
                    height: '8rem',
                  }}
                >
                  <p className="ant-upload-text">
                    {intl.formatMessage({
                      id: 'contactForm.cv.placeholder',
                    })}
                  </p>
                  <p className="ant-upload-hint">
                    <br />
                    Accepted formats: PDF, DOC, DOCX, JPG, PNG (Max: 5MB)
                  </p>
                </StyledDragger>
                <Error className="error-message" data-error="cv">
                  {errors.cv && (touched.cv || showAllErrors) && (
                    <ErrorSpan>{errors.cv}</ErrorSpan>
                  )}
                </Error>
              </FieldWrapper>
              <Button
                type="submit"
                disabled={isSubmitting || contactMutation.isPending}
              >
                {buttonText}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
