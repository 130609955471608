import emailjs from '@emailjs/browser'
import { ContactFormValues, EmailConfig } from './types'
import { BlobServiceClient } from '@azure/storage-blob'

const AZURE_STORAGE_ACCOUNT = process.env
  .REACT_APP_AZURE_STORAGE_ACCOUNT as string
const AZURE_SAS_TOKEN = process.env.REACT_APP_AZURE_SAS_TOKEN as string
const CONTAINER_NAME = process.env.REACT_APP_AZURE_CONTAINER_NAME || 'cvs'

async function uploadToAzure(file: File): Promise<string> {
  try {
    if (!AZURE_STORAGE_ACCOUNT || !AZURE_SAS_TOKEN) {
      throw new Error('Azure Storage credentials are not configured')
    }

    // Properly format the URL with the SAS token
    const blobServiceUrl = `https://${AZURE_STORAGE_ACCOUNT}.blob.core.windows.net`
    const sasToken = AZURE_SAS_TOKEN.startsWith('?')
      ? AZURE_SAS_TOKEN
      : `?${AZURE_SAS_TOKEN}`

    const blobServiceClient = new BlobServiceClient(
      `${blobServiceUrl}${sasToken}`,
    )

    const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME)
    const blobName = `${Date.now()}-${encodeURIComponent(file.name)}`
    const blockBlobClient = containerClient.getBlockBlobClient(blobName)

    // Upload the file
    await blockBlobClient.uploadData(file, {
      blobHTTPHeaders: {
        blobContentType: file.type,
        blobContentDisposition: `attachment; filename="${encodeURIComponent(
          file.name,
        )}"`,
      },
    })

    // Return the public URL of the blob (without SAS token for security)
    return blockBlobClient.url
  } catch (error) {
    console.error('Error uploading to Azure:', error)
    throw new Error(
      error instanceof Error
        ? `Failed to upload file: ${error.message}`
        : 'Failed to upload file to Azure',
    )
  }
}

export async function submitContactForm(
  values: ContactFormValues,
  config: EmailConfig,
) {
  try {
    let cvUrl = null
    if (values.cv) {
      cvUrl = await uploadToAzure(values.cv)
    }

    const emailParams = {
      ...values,
      cv: cvUrl,
      cv_filename: values.cv?.name,
      date: new Date().toISOString(),
    }

    const response = await emailjs.send(
      config.serviceId,
      config.templateId,
      emailParams,
      config.userId,
    )

    return {
      success: true,
      emailResponse: response,
      cvUrl,
    }
  } catch (error) {
    console.error('Error in submitContactForm:', error)
    throw error instanceof Error
      ? error
      : new Error('Failed to submit contact form')
  }
}
