import React, { useCallback } from 'react'
import styled from 'styled-components'
import { colors, device } from '../../../assets/Styles'

const ButtonWrapper = styled.button`
  font-size: 90%;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #fff;
  background: ${(props) => (props.isBack ? colors.dark4 : colors.dark1)};
  text-align: center;
  letter-spacing: 0.5px;
  cursor: pointer;
  position: relative;
  outline: none;
  display: inline-block;
  overflow: hidden;
  user-select: none;
  vertical-align: middle;
  transition: 0.3s ease-out;
  border: none;
  border-radius: 2px;
  height: 36px;
  float: ${(props) => (props.isBack ? 'left' : 'right')};
  line-height: 36px;
  padding: 0 16px;
  margin: ${(props) => (props.isBack ? '0 10px 0 0' : '0')};

  &:hover {
    background-color: ${(props) =>
      props.isBack ? colors.dark1 : colors.dark4};
  }

  &:disabled {
    background-color: ${colors.grey3};
    cursor: not-allowed;
    &:hover {
      background-color: ${colors.grey3};
    }
  }

  @media ${device.tablet} {
    width: 100%;
    float: none;
    margin: ${(props) => (props.isBack ? '0 0 10px 0' : '0')};
  }
`

export default function Button({
  name,
  color,
  type = 'submit',
  onClick,
  isBack = false,
  disabled = false,
}) {
  const handleClick = useCallback(
    (e) => {
      // Prevent default for back button to avoid form submission
      if (isBack) {
        e.preventDefault()
      }

      if (onClick) {
        onClick(e)
      }

      // Only scroll to top for forward navigation
      if (!isBack) {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }, 100)
      }
    },
    [onClick, isBack],
  )

  return (
    <ButtonWrapper
      color={color}
      type={isBack ? 'button' : type}
      className={`button ${isBack ? 'back' : 'forward'}`}
      onClick={handleClick}
      isBack={isBack}
      disabled={disabled}
    >
      {name}
    </ButtonWrapper>
  )
}
