// components/Contact/Contact.tsx
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ContactForm } from './ContactForm'
import SEO from '../../SEO'
import Footer from '../../shared/Footer'
import {
  CloseWrapper,
  Container,
  H1,
  Header,
  P,
  Wrapper,
} from './ContactStyles'
import { LocalizedNavLink } from '../../../modules'
import Close from '../../../assets/Close'

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 1,
    },
  },
})

const emailConfig = {
  userId: process.env.REACT_APP_EMAILJS_USER_ID || '',
  serviceId: process.env.REACT_APP_SERVICE_ID || '',
  templateId: process.env.REACT_APP_TEMPLATE_ID || '',
}

export default function Contact() {
  const intl = useIntl()
  const history = useHistory()

  const handleClose = () => {
    history.push(`/${intl.locale}/restaurant/location`)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Wrapper>
        <Container>
          <SEO
            title={intl.formatMessage({ id: 'restaurant.contactUs.title' })}
            description={intl.formatMessage({
              id: 'restaurant.contactUs.meta.description',
            })}
          />

          <Header>
            <CloseWrapper>
              <LocalizedNavLink to="restaurant/location">
                <Close color="black" />
              </LocalizedNavLink>
            </CloseWrapper>
            <H1>{intl.formatMessage({ id: 'restaurant.contactUs.h1' })}</H1>
            <P>{intl.formatMessage({ id: 'restaurant.contactUs.h2' })}</P>
          </Header>

          <ContactForm emailConfig={emailConfig} onClose={handleClose} />
        </Container>
        <Footer />
      </Wrapper>
    </QueryClientProvider>
  )
}
