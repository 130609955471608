import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { media } from '../../assets/Styles'

interface StyledProps {
  className?: string
}

interface ConfirmationProps {
  success: boolean
  resetForm: () => void
  messageId?: string
}

const Styling = styled.div.attrs({
  className: 'confirmation-wrapper',
})<StyledProps>`
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 20px; // Added horizontal padding

  ${media.phone`
    left: 0;
    transform: translateY(-50%);
  `}

  .container {
    padding: 1rem;
    margin: 0 auto;
    background: white;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%; // Added to ensure container respects padding

    p {
      text-align: center;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
      margin-top: 1rem;
    }
  }
`

export default function Confirmation({
  success,
  resetForm,
  messageId,
}: ConfirmationProps) {
  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <Styling ref={wrapperRef}>
      <div className="container">
        <p>
          {success ? (
            <FormattedMessage
              id={messageId || 'restaurant.contactUs.successMessage'}
            >
              {(message) => <span>{message}</span>}
            </FormattedMessage>
          ) : (
            <FormattedMessage id="restaurant.contactUs.failureMessage">
              {(message) => <span>{message}</span>}
            </FormattedMessage>
          )}
        </p>
        <a onClick={() => resetForm()}>
          <FormattedMessage id="restaurant.contactUs.close">
            {(message) => <span>{message}</span>}
          </FormattedMessage>
        </a>
      </div>
    </Styling>
  )
}
